import { User } from "./user";

export enum TrackProvider {
  spotify = "spotify",
  soundcloud = "soundcloud",
  youtube = "youtu",
  upload = "upload",
}

export enum TrackStatus {
  enabled = "enabled",
  deleted = "deleted",
}

export enum TrackGenre {
  afro = "Afro",
  ambient = "Ambient",
  arab = "Arab",
  asian = "Asian",
  blues = "Blues",
  childrenjingle = "Children Jingle",
  classical = "Classical",
  electronicdance = "Electronic / Dance",
  folkcountry = "Folk / Country",
  funksoul = "Funk / Soul",
  indian = "Indian",
  jazz = "Jazz",
  latin = "Latin",
  metal = "Metal",
  pop = "Pop",
  raphiphop = "Rap / Hip-Hop",
  reggae = "Reggae",
  rnb = "RnB",
  rock = "Rock",
  singersongwriters = "Singer / Songwriters",
  sound = "Sound",
  soundtrack = "Soundtrack",
  spokenword = "Spoken Word",

  // sub genres
  bluesrock = "Blues / Rock",
  folkrock = "Folk / Rock",
  hardrock = "Hard / Rock",
  indiealternative = "Indie / Alternative",
  psychedelicprogressiverock = "Psychedelic / Progressive / Rock",
  punk = "Punk",
  rockandroll = "Rock And Roll",
  popsoftrock = "Pop / Soft Rock",
  abstractidmleftfield = "Abstract IDM Leftfield",
  breakbeatdnb = "Breakbeat DnB",
  deephouse = "Deep House",
  electro = "Electro",
  house = "House",
  minimal = "Minimal",
  synthpop = "Synth Pop",
  techhouse = "Tech House",
  techno = "Techno",
  trance = "Trance",
  contemporaryrnb = "Contemporary RnB",
  gangsta = "Gangsta",
  jazzyhiphop = "Jazzy Hip-Hop",
  poprap = "Pop / Rap",
  trap = "Trap",
  blackmetal = "Black Metal",
  deathmetal = "Death Metal",
  doommetal = "Doom Metal",
  heavymetal = "Heavy Metal",
  metalcore = "Metalcore",
  numetal = "Nu Metal",
  disco = "Disco",
  funk = "Funk",
  gospel = "Gospel",
  neosoul = "Neo Soul",
  soul = "Soul",
  bigbandswing = "Big Band Swing",
  bebop = "Bebop",
  contemporaryjazz = "Contemporary Jazz",
  easylistening = "Easy Listening",
  fusion = "Fusion",
  latinjazz = "Latin Jazz",
  smoothjazz = "Smooth Jazz",
  country = "Country",
  folk = "Folk",
}

export enum Key {
  aminor = "A Minor",
  eminor = "E Minor",
  bminor = "B Minor",
  fsminor = "F# Minor",
  csminor = "C# Minor",
  gsminor = "G# Minor",
  dsminor = "D# Minor",
  bbminor = "B-flat Minor",
  fminor = "F Minor",
  cminor = "C Minor",
  gminor = "G Minor",
  dminor = "D Minor",
  cmajor = "C Major",
  gmajor = "G Major",
  dmajor = "D Major",
  amajor = "A Major",
  emajor = "E Major",
  bmajor = "B Major",
  fsmajor = "F# Major",
  dbmajor = "D-flat Major",
  abmajor = "A-flat Major",
  ebmajor = "E-flat Major",
  bbmajor = "B-flat Major",
  fmajor = "F Major",
}

export enum EnergyLevel {
  variable = "Variable",
  medium = "Medium",
  high = "High",
  low = "Low",
}

export enum Mood {
  aggressive = "Aggressive",
  calm = "Calm",
  chilled = "Chilled",
  dark = "Dark",
  energetic = "Energetic",
  epic = "Epic",
  happy = "Happy",
  romantic = "Romantic",
  sad = "Sad",
  scary = "Scary",
  sexy = "Sexy",
  ethereal = "Ethereal",
  uplifting = "Uplifting",
}

export enum MovementTags {
  bouncy = "Bouncy",
  driving = "Driving",
  flowing = "Flowing",
  groovy = "Groovy",
  nonrhythmic = "Nonrhythmic",
  pulsing = "Pulsing",
  robotic = "Robotic",
  running = "Running",
  steady = "Steady",
  stomping = "Stomping",
}

export enum Vocal {
  female = "Female",
  instrumental = "Instrumental",
  male = "Male",
}

export interface Track {
  _id: string;
  createdAt: string;
  userId: string;
  user?: User;
  provider: TrackProvider;
  link: string;
  fileUrl?: string;
  externalId: string;
  title: string;
  status: TrackStatus;
  description: string;
  coverUrl?: string; // image hosted by the provider
  genres: string[];
  isReleased?: boolean;
}

export interface PlaylistTrack {
  preview: TrackPreview;
  type: string[];
  isReleased: boolean;
  trackId?: string;
  externalId?: string;
}

export interface Statistics {
  releaseDate?: string;
  commentCount?: number;
  likesCount?: number;
  playbackCount?: number;
  channelSubscribeCount?: number;
  channelViewCount?: number;
  tracksCount?: number;
  channelLikesCount?: number;
  popularity?: number;
  labelName?: string;
  monthlyListeners?: number;
  chartmetricArtistRank?: number;
  tiktokFollowers?: number;
  instagramFollowers?: number;
  playlistEditorials?: number;
}

export const userStatsKeys = [
  "channelSubscribeCount",
  "channelViewCount",
  "channelLikesCount",
  "tracksCount",
  "popularity",
  "monthlyListeners",
  "chartmetricArtistRank",
  "tiktokFollowers",
  "instagramFollowers",
  "playlistEditorials",
];

export const songStatsKeys = [
  "releaseDate",
  "likesCount",
  "commentCount",
  "playbackCount",
];

export enum StatisticTitleByKey {
  releaseDate = "Release Date",
  commentCount = "Comments",
  likesCount = "Likes",
  playbackCount = "Playbacks",
  channelSubscribeCount = "Channel Subscribers",
  channelViewCount = "Channel Views",
  tracksCount = "Tracks",
  channelLikesCount = "Channel Likes",
  popularity = "Popularity",
  labelName = "Label",
  monthlyListeners = "Mo. listeners",
  chartmetricArtistRank = "Artist Rank",
  tiktokFollowers = "Followers",
  instagramFollowers = "Followers",
  playlistEditorials = "Playlist Editorials",
}

export interface TrackError {
  linkError: string;
  titleError: string;
  descriptionError: string;
}

export enum Provider {
  soundcloud = "soundcloud",
  youtube = "youtube",
  youtu = "youtu",
  spotify = "spotify",
  upload = "upload",
}

export interface TrackPreview {
  title?: string;
  provider?: TrackProvider;
  previewUrl?: string;
  isPrivate?: boolean;
  thumbnailUrl?: string;
  streamUrl?: string;
}
